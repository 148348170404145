import "./App.less";

import Wheel from "./pages/Wheel";
import WinningRecord from "./pages/WinningRecord";
import RankingList from "./pages/RankingList";
import ActivityDescription from "./pages/ActivityDescription";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store } from "./store";
import { Provider } from "react-redux";
import { useEffect } from "react";
import { setConfig } from "./store/app";
import Http from "./utils/Http";
function App () {

  const getConfig = async () => {
    const res = await Http.postForm('/api/v1/common/config');
    if (res.status === 10000) {
      store.dispatch(setConfig(res.data));
    }
  }

  useEffect(() => {
    getConfig()
  }, [])

  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/dazhuanpan" element={<Wheel />} />
            <Route path="/dazhuanpan/winning/record" element={<WinningRecord />} />
            <Route path="/dazhuanpan/ranking/list" element={<RankingList />} />
            <Route
              path="/dazhuanpan/activity/description"
              element={<ActivityDescription />}
            />
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
