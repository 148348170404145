import React from "react";
import { Modal } from "antd";
import "./index.less";
import { List } from "antd";

const btn_close_line = require("../../../../assets/btn_close_line@3x.png");
const headerpng = require("../../../../assets/header.png");
// const closes = require("../../../../assets/shengdeng/close.png");
// const confirm = require("../../../../assets/shengdeng/confirm.png");
const closes = require("../../../../assets/gaobai/closes.png");
const confirm = require("../../../../assets/gaobai/submit.png");

const findIndex = (item, productList) => {
  if (!item || !productList) {
    return false;
  }

  let index = productList?.findIndex((ele) => ele?.product_id === item?.id);

  if (index === -1) {
    return true;
  } else {
    return false;
  }
};

const CountArr = (arr) => {
  // let newJson = []; //盛放去重后数据的新数组
  // for(let item1 of arr){  //循环json数组对象的内容
  //     let flag = true;  //建立标记，判断数据是否重复，true为不重复
  //     for(let item2 of newJson){  //循环新数组的内容
  //
  //         if(item1.id===item2.id){ //让json数组对象的内容与新数组的内容作比较，相同的话，改变标记为false
  //             flag = false;
  //         }
  //     }
  //     if(flag){ //判断是否重复
  //         newJson.push(item1); //不重复的放入新数组。  新数组的内容会继续进行上边的循环。
  //     }
  // }
  // console.log("newJson",newJson);
  //
  //
  // // 遍历得到的新数组，计算重复的数量
  // for (let item of newJson) {
  //
  // }

  let newArr = [...new Set(arr.map((i) => i.name))]; // 去重的时候需要注意和普通数组不同
  console.log(newArr);
  let list = [];
  newArr.forEach((i) => {
    list.push(arr.filter((t) => t.name === i));
  });
  var mlist = [];
  list.forEach((i, index) => {
    mlist.push({
      name: newArr[index],
      num: i.length,
      avatar: list[index][0].goods_info.icon_path,
    });
  });
  console.log("newarr", mlist);

  return mlist;
};

const Item = (props) => {
  const { item, productList } = props;

  const isShow = findIndex(item, productList);

  return (
    <div className="goods-modal-item">
      {/*<div className='goods-modal-item-right'>*/}
      {/*    <div>*/}
      {/*        <img*/}
      {/*            src={item?.goods_info?.icon_path}*/}
      {/*            alt=''*/}
      {/*            style={{*/}
      {/*                height: 40,*/}
      {/*                width: 40,*/}
      {/*            }}*/}
      {/*        />*/}
      {/*    </div>*/}
      {/*    <br/>*/}
      {/*  <div className='goods-model-item-text'>*/}
      {/*    <span*/}
      {/*      style={{*/}
      {/*        color: '#343537',*/}
      {/*        fontSize: 15,*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {item?.name}{' '}*/}
      {/*    </span>*/}
      {/*    <span*/}
      {/*      style={{*/}
      {/*        color: '#F03550',*/}
      {/*        fontSize: 15,*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      *1{' '}*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div
        className="goods-model-one"
        style={{
          marginTop: 0,
        }}
      >
        <div className="goods-model-one-img">
          <img
            alt=""
            src={item?.avatar}
            style={{
              width: 50,
              height: 50,
            }}
          />
        </div>
        <div
          className="goods-model-one-text"
          style={{
            textAlign: "center",
          }}
        >
          <span
            style={{
              color: "#343537",
              fontSize: 12,
            }}
          >
            {item?.name}{" "}
          </span>
          <span
            style={{
              color: "#F03550",
              fontSize: 12,
            }}
          >
            *{item?.num}{" "}
          </span>
        </div>
        {/*<div className='goods-model-one-description'>*/}
        {/*  {targetData.data[0]?.goods_info?.sale_price ?? 0}声豆*/}
        {/*</div>*/}
      </div>

      {/*{isShow && <div className='goods-modal-item-show'>隐藏款</div>}*/}

      {/*<div className='goods-modal-item-left'>*/}
      {/*  {item?.goods_info?.sale_price}声豆*/}
      {/*</div>*/}
    </div>
  );
};

const GoodsModel = React.forwardRef((props, ref) => {
  const { targetData, lottery, index, data, productList } = props;

  console.log("[targetData]", targetData.data, productList);

  const [visible, setVisible] = React.useState(false); // todo 调试弹窗

  const isShow = findIndex(targetData?.data?.[0], productList);

  const close = () => {
    setVisible(false);
  };

  const show = () => {
    setVisible(true);
  };

  console.log(targetData.data);

  // 进行合并同类项
  let newData = targetData.data;
  const newDatas = CountArr(newData);
  React.useImperativeHandle(ref, () => {
    return {
      show: show,
    };
  });

  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={close}
      bodyStyle={{
        backgroundColor: "transparent",
      }}
      style={{ top: 20 }}
      width={322}
      closable={false}
      footer={null}
    >
      <div className="goods-modal">
        <div className="goods-modal-container">
          {/* <div className="goods-modal-title">
            <img
              src={headerpng}
              style={{
                width: 250,
                height: 150,
              }}
              alt=""
            />
          </div> */}

          {targetData?.data?.length === 1 ? (
            <>
              {isShow && (
                <div className="goods-modal-title-show">
                  {/* 疯狂大转盘隐藏款奖品 */}
                </div>
              )}
              <div className="goods-model-one">
                <div className="goods-model-one-img">
                  <img
                    alt=""
                    src={targetData.data[0]?.goods_info?.icon_path}
                    style={{
                      width: 90,
                      height: 90,
                    }}
                  />
                </div>
                <div className="goods-model-one-text">
                  <span
                    style={{
                      color: "#343537",
                      fontSize: 15,
                    }}
                  >
                    {targetData.data[0]?.name}{" "}
                  </span>
                  <span
                    style={{
                      color: "#F03550",
                      fontSize: 15,
                    }}
                  >
                    *1{" "}
                  </span>
                </div>
                {/*<div className='goods-model-one-description'>*/}
                {/*  {targetData.data[0]?.goods_info?.sale_price ?? 0}声豆*/}
                {/*</div>*/}
                {/* <div
                  className="goods-model-one-button"
                  onClick={() => {
                    setVisible(false);
                    lottery(data[index].id, targetData.count);
                  }}
                >
                  再抽{targetData.count}次
                </div> */}
                <div className="bottom_button">
                  <div>请去背包里查看奖品</div>
                  <img
                    src={confirm}
                    onClick={() => {
                      setVisible(false);
                    }}
                  ></img>
                </div>
              </div>
            </>
          ) : (
            <div className="goods-model-list">
              <List
                grid={{
                  gutter: 12,
                  xs: 3,
                }}
                style={{
                  maxHeight: 298,
                  overflow: "hidden",
                  overflowY: "auto",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
                dataSource={newDatas ?? []}
                renderItem={(item, index) => (
                  <Item key={index} item={item} productList={productList} />
                )}
              />
              {/* <div
                className="goods-model-list-button"
                onClick={() => {
                  setVisible(false);
                  lottery(data[index].id, targetData.count);
                }}
              >
                再抽{targetData.count}次
              </div> */}
              <div className="bottom_button">
                <div>请去背包里查看奖品</div>
                <img
                  src={confirm}
                  onClick={() => {
                    setVisible(false);
                  }}
                ></img>
              </div>
            </div>
          )}
        </div>
        <div
          className="goods-model-close"
          onClick={() => {
            setVisible(false);
          }}
        >
          <img
            src={closes}
            style={{
              width: 40,
              height: 40,
            }}
            alt=""
          />
        </div>
      </div>
    </Modal>
  );
});

export default GoodsModel;
