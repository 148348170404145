import React, { useRef, useEffect, useState } from "react";
import { LuckyWheel } from "@lucky-canvas/react";
import { useInterval } from "usehooks-ts";
import "./index.less";
import { log } from "@craco/craco/lib/logger";

import SVGA from "svgaplayerweb";

// const bg_zp_chuji_1_1 = require('../../../../assets/bg_zp_chuji_1_1@3x.png');
// const bg_zp_chuji_1 = require('../../../../assets/bg_zp_chuji_1@3x.png');
// const bg_zp_chuji_2 = require('../../../../assets/bg_zp_chuji_2@3x.png');
// const bg_zp_chuji_3 = require('../../../../assets/bg_zp_chuji_3@3x.png');
// const bg_zp_chuji_4 = require('../../../../assets/bg_zp_chuji_4@3x.png');
const bg_zp_chuji_1_1 = require("../../../../assets/motianlun/ray.png");
const bg_zp_chuji_1 = require("../../../../assets/motianlun/ray2.png");
const bg_zp_chuji_2 = require("../../../../assets/bg_zp_chuji_2@3x.png");
const bg_zp_chuji_3 = require("../../../../assets/motianlun/lowYuanpan.png");
const bg_zp_chuji_4 = require("../../../../assets/motianlun/lowGo.png");
const bg_zp_chuji_5 = require("../../../../assets/motianlun/lowHome.png");
const bg_zp_chuji_6 = require("../../../../assets/shengdeng/xunhBg.png");
// const svgatest = require("../../../../assets/shengdeng/xunh.svga");
// const svgatestB = require("../../../../assets/shengdeng/start.svga");
const svgatest = require("../../../../assets/gaobai/xunh2.svga");
const svgatestB = require("../../../../assets/gaobai/start.svga");

const formatPrize = (item) => {
  return {
    imgs: [
      {
        src: item?.product_info?.goods_info?.icon_path,
        width: "45px",
        top: "15%",
        left: "3px",
      },
    ],
    // fonts: [
    //   {
    //     text: `${item?.product_info?.skuList[0]?.pay_amount}${item?.product_info?.asset_info?.display_name}`,
    //     // top: "15%",
    //     // fontSize: "12px",
    //     fontColor: "#5131cd",
    //     top: "80%",
    //     fontSize: "12px",
    //   },
    // ],
  };
};

const BaseWheel = React.forwardRef((props, ref) => {
  const { id, productList, findIndex, showGiftModel, selectState, lottery } =
    props;
  const [bg, setBg] = React.useState(true);
  const [speeds, setSpeed] = React.useState(1);
  const [svgaShow, setSvgaShow] = React.useState(false);

  const [shows, setShows] = React.useState(true);
  const playState = React.useRef({
    more: false,
    ids: [],
  });
  const selectStateRef = React.useRef({
    more: false,
    ids: [],
  });

  const [blocks, setBlacks] = React.useState([
    {
      padding: "20px",
      imgs: [
        {
          src: bg_zp_chuji_1_1,
          width: "100%",
          height: "100%",
          rotate: true,
        },
      ],
    },
    // {
    //   padding: "12px",
    //   imgs: [
    //     {
    //       src: bg_zp_chuji_2,
    //       width: "100%",
    //       height: "100%",
    //       rotate: true,
    //     },
    //   ],
    // },
    {
      imgs: [
        {
          src: bg_zp_chuji_3,
          width: "95%",
          height: "95%",
          top: "2px",
          rotate: true,
        },
      ],
    },
    // {
    //   imgs: [
    //     {
    //       src: bg_zp_chuji_5,
    //       width: "130%",
    //       height: "200%",
    //       top: "-180px",
    //     },
    //   ],
    // },
  ]);
  const [prizes, setPrizes] = React.useState(productList?.map(formatPrize));
  const [buttons] = React.useState([
    // {
    //   radius: "45%",
    //   imgs: [
    //     {
    //       src: bg_zp_chuji_4,
    //       width: "46%",
    //       top: "-35",
    //     },
    //   ],
    // },
    {
      fonts: [
        {
          text: "",
          top: "-14px",
          fontSize: 22,
          fontColor: "#ffffff",
        },
      ],
    },
  ]);
  const myLucky = React.useRef();

  //灯光闪烁

  // useInterval(() => {
  //   if (bg) {
  //     setBlacks((pre) => {
  //       pre[0].imgs[0].src = bg_zp_chuji_1_1;
  //       return [...pre];
  //     });
  //   } else {
  //     setBlacks((pre) => {
  //       pre[0].imgs[0].src = bg_zp_chuji_1;
  //       return [...pre];
  //     });
  //   }
  //   setBg(!bg);
  // }, 800);

  React.useEffect(() => {
    setPrizes(productList?.map(formatPrize));
  }, [productList]);

  const onEnd = () => {
    if (playState.current.ids.length === 0) {
      showGiftModel();
      return;
    }

    let item = playState.current.ids.shift();

    if (playState.current.more) {
      start(item, 1000);
      playState.current.more = false;
    } else {
      start(item, 1000);
    }
  };

  const stop = (index) => {
    myLucky.current.stop(index);
  };

  const start = (index, duration) => {
    setTimeout(() => {
      stop(index);
    }, duration);
  };

  const play = (count, data) => {
    if (count > 1) {
      let max = Number(data[0]?.pay_amount);
      let max_index = 0;

      data?.forEach((item, index) => {
        if (Number(item.pay_amount) > max) {
          max_index = index;
          max = Number(item.pay_amount);
        }
      });

      let target = findIndex(data[max_index]?.goods_info);

      playState.current = {
        more: true,
        ids: [target],
      };
    } else {
      let length = data.length;
      let target = findIndex(data[length - 1]?.goods_info);

      playState.current = {
        more: false,
        ids: [target],
      };
    }
    let item = playState.current.ids.shift();

    if (playState.current.more) {
      start(item, 1000);
      playState.current.more = false;
    } else {
      start(item, 2000);
    }
  };

  const loading = (count, data) => {
    myLucky.current.play();
  };

  const onStart = () => {
    lottery && lottery(selectStateRef.current.id, selectStateRef.current.count);
  };

  React.useEffect(() => {
    selectStateRef.current = {
      ...selectState,
      id: id,
    };
    console.log(99998);
  }, [selectState, id]);

  React.useImperativeHandle(ref, () => {
    return {
      play: play,
      stop: stop,
      loading: loading,
      onStart: onStart,
      cutStart: cutStart,
      noStart: noStart
    };
  });
  React.useEffect(() => {
    // onStart();
    Svga();
    SvgaB();
  }, []);
  const Svga = async () => {
    var player = new SVGA.Player("#demoCanvas");
    var parser = new SVGA.Parser("#demoCanvas"); // 如果你需要支持 IE6+，那么必须把同样的选择器传给 Parser。
    parser.load(svgatest, function (videoItem) {
      player.setVideoItem(videoItem);
      player.startAnimation();
    });
  };
  const SvgaB = async () => {
    var player = new SVGA.Player("#demoCanvasB");
    var parser = new SVGA.Parser("#demoCanvasB"); // 如果你需要支持 IE6+，那么必须把同样的选择器传给 Parser。
    parser.load(svgatestB, function (videoItem) {
      player.setVideoItem(videoItem);
      player.startAnimation();
    });
  };
  const cutStart = () => {
    console.log("执行了开始");
    // setSvgaShow(true);
    // Svga(svgatestB);
    // setTimeout(() => {
    //   Svga(svgatest);
    // }, 3000);
    // if (shows) {
    //   SvgaB();
    // }
    setShows(false);
    setTimeout(() => {
      setShows(true);
    }, 2000);
  };
  const noStart = () => {
    console.log('结束');
    setShows(true);
  };

  return (
    // <LuckyWheel
    //   ref={myLucky}
    //   width={"360px"}
    //   height={"360px"}
    //   blocks={blocks}
    //   prizes={prizes}
    //   buttons={buttons}
    //   defaultConfig={{
    //     speed: 6,
    //     offsetDegree: 20,
    //     accelerationTime: 2500,
    //     decelerationTime: 2500,
    //   }}
    //   onEnd={onEnd}
    //   onStart={onStart}
    // />
    <div className="center_img">
      {/* <div className="center_img_bg">
        <img src={bg_zp_chuji_6}></img>
      </div> */}
      {/* {shows  === true ? ( */}
      <div
        id="demoCanvas"
        className={shows ? "svga" : "svgaB"}
        loops="0"
      ></div>
      {/* ) : ( */}
      <div
        id="demoCanvasB"
        className={shows ? "svgaB" : "svga"}
        loops="0"
      // clearsAfterStop="true"
      ></div>
      {/* )} */}

      {/* <div className="aas">
        {height},{width}
      </div> */}
    </div>
  );
});
export default BaseWheel;
