import React from "react";
import StarAdd from "./components/StarAdd";
import Text from "./components/Text";
import BaseWheel from "./components/BaseWheel";
import GoodsModel from "./components/GoodsModel";
import InputNumberModel from "./components/InputNumberModel";
import "./index.less";
import Http from "../../utils/Http";
import { message, Spin, Popover } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import AdvanceWheel from "./components/AdvanceWheel";
import { useSelector, useDispatch } from "react-redux";
import { setToken } from "../../store/app";
import { useNavigate } from "react-router-dom";
import MiddleWheel from "./components/MiddleWheel";
//
import Windows from "./components/window/indes";
import Zjwindow from "./components/zhongjWindow/indes";

import { log } from "@craco/craco/lib/logger";
const welcomes = require("../Wheel/components/MiddleWheel/assets/alterWheel/top_text@3x.png");
const welcome = require("../../assets/motianlun/headerText.png");
const logo = require("../../assets/shengdeng/logo.png");
const btn_zp_menu = require("../../assets/btn_zp_menu@3x.png");
const go1 = require("../../assets/motianlun/lowGo.png");
const go2 = require("../../assets/motianlun/centerGo.png");
const go3 = require("../../assets/motianlun/highGo.png");
// const bg_zp_chuji_5 = require("../../assets/motianlun/lowHome.png");
// const bg_zp_chuji_6 = require("../../assets/motianlun/centerHome.png");
// const bg_zp_chuji_7 = require("../../assets/motianlun/highHome.png");
const mixJingdu = require("../../assets/gaobai/min.png");
const iconImg = require("../../assets/gaobai/icon.png");
const MaxImg = require("../../assets/gaobai/max.png");
// const commonImg = require("../../assets/shengdeng/common.png");
// const luckyImg = require("../../assets/shengdeng/lucky.png");
const commonImg = require("../../assets/gaobai/common.png");
const luckyImg = require("../../assets/gaobai/luck.png");
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Wheel = () => {
  const tabs = ["初级", "中级", "高级"];

  console.log("Wheel render");

  const paramsStr = window.location.search;
  const params = new URLSearchParams(paramsStr);

  const navigate = useNavigate();
  const storeToken = useSelector((state) => state.App.token);
  const dispatch = useDispatch();

  const token = params.get("token");
  const myLucky = React.useRef(null);
  const goodsModelRef = React.useRef(null);
  const inputNumberModelRef = React.useRef(null);
  const [index, setIndex] = React.useState(0);
  const [selectState, setSelectState] = React.useState({
    mode: 1,
    count: 1,
  });

  const [disable, setDisable] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [initLoading, setInitLoading] = React.useState(true);

  const [data, setData] = React.useState([]);
  const [targetData, setTargetData] = React.useState({
    count: 0,
    data: [],
  });
  const [count, setCount] = React.useState(null);
  const [customCount, setCustomCount] = React.useState(66);
  const [shengDou, setShengDou] = React.useState(null);
  const [record, setRecord] = React.useState([]);
  const [convert, setConvert] = React.useState(true);

  const error = React.useRef(false);
  const WindowRef = React.useRef(null);
  const zhongWindowRef = React.useRef(null);
  //移除组件旋转
  const [isRotating, setIsRotating] = React.useState(true);
  const price = data[index]?.price ?? 0;
  const showGiftModel = () => {
    if (error.current) {
      setDisable(false);
      return;
    }

    goodsModelRef.current.show();
    setDisable(false);
    // setIsRotating(true);
  };

  const returnConvert = () => {
    return convert;
  };

  const findIndex = (goods_info) => {
    const productList = data[index]?.product_list ?? [];
    const target = productList?.findIndex(
      (item) => item?.product_info?.goods_info?.id === goods_info?.id
    );
    return target;
  };

  const getData = async () => {
    try {
      const res = await Http.postForm(
        "/api/v1/turntable/list",
        {
          category: 1,
        },
        undefined
      );
      if (res?.status === 10000) {
        setData(res?.data);
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      // message.error("网络错误，请检查网络设置");
    } finally {
    }
  };

  const getWalletList = async () => {
    try {
      const res = await Http.postForm(
        "/api/v1/user/walletList",
        {
          category: 1,
        },
        undefined
      );

      if (res?.status === 10000) {
        const wallet = res?.data?.find((item) => item?.asset === "jifen");
        const shengDouWallet = res?.data?.find(
          (item) => item?.asset === "shengdou"
        );

        setCount(wallet?.free_number ?? 0);
        setShengDou(shengDouWallet?.free_number ?? 0);
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      // message.error("网络错误，请检查网络设置");
    } finally {
    }
  };
  //最近中奖
  ///api/v1/turntable/turntableRes
  const getTurntableRes = async () => {
    setTurntask(false);
    try {
      const res = await Http.get("/api/v1/lucky_bot_game/lotteryLatestRes", {
        pers: 10,
        page: 1,
      });

      if (res?.status === 10000) {
        setRecord(res?.data);
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      // message.error("网络错误，请检查网络设置");
    } finally {
      setTurntask(true);
    }
  };

  const lottery = async (id, num) => {
    if (loading) {
      return;
    }
    setLoading(true);
    setConvert(false);
    setTargetData({
      count: num,
      data: [],
    });
    let sendConvert = true;
    if (!document.getElementById("convertSwitch")) {
      sendConvert = true;
    } else {
      sendConvert = document.getElementById("convertSwitch").ariaChecked;
    }
    setIsRotating(false);
    // myLucky.current.loading();
    //模拟
    // let datt = [
    //   {
    //     id: 332,
    //     created_at: "2023-10-09T22:48:55+08:00",
    //     updated_at: "2023-10-09T22:48:55+08:00",
    //     name: "杯子",
    //     pic: "",
    //     category: 1,
    //     pay_asset: "shengdou",
    //     asset_info: {
    //       id: 0,
    //       created_at: "0001-01-01T00:00:00Z",
    //       updated_at: "0001-01-01T00:00:00Z",
    //       name: "",
    //       icon: "",
    //       display_name: "",
    //       status: 0,
    //       wallet_name: "",
    //     },
    //     goods_id: 446,
    //     item_type: "6",
    //     goods_info: {
    //       id: 446,
    //       created_at: "2023-10-09T22:11:43+08:00",
    //       updated_at: "2023-12-19T15:09:19+08:00",
    //       name: "杯子",
    //       desc: "杯子",
    //       item_class: "1",
    //       item_type: "6",
    //       item_level: "1",
    //       is_in_package: 1,
    //       is_unique: 1,
    //       can_use: 1,
    //       attr_info: "杯子",
    //       sequence: 0,
    //       effect_param: "",
    //       icon_path:
    //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/47c10085-a09f-4014-b7c8-5fb29572e4e8.png",
    //       show_resource_path:
    //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/1b9f2561-4802-4644-83b4-56f6c8f56884.svga.svga",
    //       show_resource_type: "full",
    //       tid: "BEIZI",
    //       can_sale: 0,
    //       sale_price: "10",
    //       can_combine: 0,
    //       is_stack: 1,
    //       stack_limit: 999999,
    //       giftwall_status: 1,
    //     },
    //     sequence: 0,
    //     status: 0,
    //     skuList: [
    //       {
    //         id: 341,
    //         created_at: "2023-10-09T22:48:55+08:00",
    //         updated_at: "2023-10-09T22:48:55+08:00",
    //         product_id: 332,
    //         pay_amount: "10",
    //         quantity: 1,
    //         effective_time: 0,
    //         sequence: 0,
    //         status: 1,
    //         send_asset: "",
    //         send_amount: "",
    //       },
    //     ],
    //     user_used: 0,
    //     in_package_id: 0,
    //     product_category: 0,
    //     xianding: 0,
    //     total_stock: 0,
    //     stock: 0,
    //     stock_show: 0,
    //     xianding_start_time: null,
    //     xianding_end_time: null,
    //     level_need: 0,
    //     level_info: {
    //       id: 0,
    //       created_at: "0001-01-01T00:00:00Z",
    //       updated_at: "0001-01-01T00:00:00Z",
    //       category: "",
    //       name: "",
    //       icon: "",
    //       sequence: 0,
    //       threshold: 0,
    //       key: "",
    //       grade: 0,
    //     },
    //   },
    //   {
    //     id: 332,
    //     created_at: "2023-10-09T22:48:55+08:00",
    //     updated_at: "2023-10-09T22:48:55+08:00",
    //     name: "杯子33",
    //     pic: "",
    //     category: 1,
    //     pay_asset: "shengdou",
    //     asset_info: {
    //       id: 0,
    //       created_at: "0001-01-01T00:00:00Z",
    //       updated_at: "0001-01-01T00:00:00Z",
    //       name: "",
    //       icon: "",
    //       display_name: "",
    //       status: 0,
    //       wallet_name: "",
    //     },
    //     goods_id: 446,
    //     item_type: "6",
    //     goods_info: {
    //       id: 446,
    //       created_at: "2023-10-09T22:11:43+08:00",
    //       updated_at: "2023-12-19T15:09:19+08:00",
    //       name: "杯子33",
    //       desc: "杯子33",
    //       item_class: "1",
    //       item_type: "6",
    //       item_level: "1",
    //       is_in_package: 1,
    //       is_unique: 1,
    //       can_use: 1,
    //       attr_info: "杯子33",
    //       sequence: 0,
    //       effect_param: "",
    //       icon_path:
    //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/47c10085-a09f-4014-b7c8-5fb29572e4e8.png",
    //       show_resource_path:
    //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/1b9f2561-4802-4644-83b4-56f6c8f56884.svga.svga",
    //       show_resource_type: "full",
    //       tid: "BEIZI",
    //       can_sale: 0,
    //       sale_price: "10",
    //       can_combine: 0,
    //       is_stack: 1,
    //       stack_limit: 999999,
    //       giftwall_status: 1,
    //     },
    //     sequence: 0,
    //     status: 0,
    //     skuList: [
    //       {
    //         id: 341,
    //         created_at: "2023-10-09T22:48:55+08:00",
    //         updated_at: "2023-10-09T22:48:55+08:00",
    //         product_id: 332,
    //         pay_amount: "10",
    //         quantity: 1,
    //         effective_time: 0,
    //         sequence: 0,
    //         status: 1,
    //         send_asset: "",
    //         send_amount: "",
    //       },
    //     ],
    //     user_used: 0,
    //     in_package_id: 0,
    //     product_category: 0,
    //     xianding: 0,
    //     total_stock: 0,
    //     stock: 0,
    //     stock_show: 0,
    //     xianding_start_time: null,
    //     xianding_end_time: null,
    //     level_need: 0,
    //     level_info: {
    //       id: 0,
    //       created_at: "0001-01-01T00:00:00Z",
    //       updated_at: "0001-01-01T00:00:00Z",
    //       category: "",
    //       name: "",
    //       icon: "",
    //       sequence: 0,
    //       threshold: 0,
    //       key: "",
    //       grade: 0,
    //     },
    //   },
    //   {
    //     id: 332,
    //     created_at: "2023-10-09T22:48:55+08:00",
    //     updated_at: "2023-10-09T22:48:55+08:00",
    //     name: "杯子66",
    //     pic: "",
    //     category: 1,
    //     pay_asset: "shengdou",
    //     asset_info: {
    //       id: 0,
    //       created_at: "0001-01-01T00:00:00Z",
    //       updated_at: "0001-01-01T00:00:00Z",
    //       name: "",
    //       icon: "",
    //       display_name: "",
    //       status: 0,
    //       wallet_name: "",
    //     },
    //     goods_id: 446,
    //     item_type: "6",
    //     goods_info: {
    //       id: 446,
    //       created_at: "2023-10-09T22:11:43+08:00",
    //       updated_at: "2023-12-19T15:09:19+08:00",
    //       name: "杯子66",
    //       desc: "杯子66",
    //       item_class: "1",
    //       item_type: "6",
    //       item_level: "1",
    //       is_in_package: 1,
    //       is_unique: 1,
    //       can_use: 1,
    //       attr_info: "杯子66",
    //       sequence: 0,
    //       effect_param: "",
    //       icon_path:
    //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/47c10085-a09f-4014-b7c8-5fb29572e4e8.png",
    //       show_resource_path:
    //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/1b9f2561-4802-4644-83b4-56f6c8f56884.svga.svga",
    //       show_resource_type: "full",
    //       tid: "BEIZI",
    //       can_sale: 0,
    //       sale_price: "10",
    //       can_combine: 0,
    //       is_stack: 1,
    //       stack_limit: 999999,
    //       giftwall_status: 1,
    //     },
    //     sequence: 0,
    //     status: 0,
    //     skuList: [
    //       {
    //         id: 341,
    //         created_at: "2023-10-09T22:48:55+08:00",
    //         updated_at: "2023-10-09T22:48:55+08:00",
    //         product_id: 332,
    //         pay_amount: "10",
    //         quantity: 1,
    //         effective_time: 0,
    //         sequence: 0,
    //         status: 1,
    //         send_asset: "",
    //         send_amount: "",
    //       },
    //     ],
    //     user_used: 0,
    //     in_package_id: 0,
    //     product_category: 0,
    //     xianding: 0,
    //     total_stock: 0,
    //     stock: 0,
    //     stock_show: 0,
    //     xianding_start_time: null,
    //     xianding_end_time: null,
    //     level_need: 0,
    //     level_info: {
    //       id: 0,
    //       created_at: "0001-01-01T00:00:00Z",
    //       updated_at: "0001-01-01T00:00:00Z",
    //       category: "",
    //       name: "",
    //       icon: "",
    //       sequence: 0,
    //       threshold: 0,
    //       key: "",
    //       grade: 0,
    //     },
    //   },
    //   {
    //     id: 332,
    //     created_at: "2023-10-09T22:48:55+08:00",
    //     updated_at: "2023-10-09T22:48:55+08:00",
    //     name: "杯子6s",
    //     pic: "",
    //     category: 1,
    //     pay_asset: "shengdou",
    //     asset_info: {
    //       id: 0,
    //       created_at: "0001-01-01T00:00:00Z",
    //       updated_at: "0001-01-01T00:00:00Z",
    //       name: "",
    //       icon: "",
    //       display_name: "",
    //       status: 0,
    //       wallet_name: "",
    //     },
    //     goods_id: 446,
    //     item_type: "6",
    //     goods_info: {
    //       id: 446,
    //       created_at: "2023-10-09T22:11:43+08:00",
    //       updated_at: "2023-12-19T15:09:19+08:00",
    //       name: "杯子66",
    //       desc: "杯子66",
    //       item_class: "1",
    //       item_type: "6",
    //       item_level: "1",
    //       is_in_package: 1,
    //       is_unique: 1,
    //       can_use: 1,
    //       attr_info: "杯子66",
    //       sequence: 0,
    //       effect_param: "",
    //       icon_path:
    //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/47c10085-a09f-4014-b7c8-5fb29572e4e8.png",
    //       show_resource_path:
    //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/1b9f2561-4802-4644-83b4-56f6c8f56884.svga.svga",
    //       show_resource_type: "full",
    //       tid: "BEIZI",
    //       can_sale: 0,
    //       sale_price: "10",
    //       can_combine: 0,
    //       is_stack: 1,
    //       stack_limit: 999999,
    //       giftwall_status: 1,
    //     },
    //     sequence: 0,
    //     status: 0,
    //     skuList: [
    //       {
    //         id: 341,
    //         created_at: "2023-10-09T22:48:55+08:00",
    //         updated_at: "2023-10-09T22:48:55+08:00",
    //         product_id: 332,
    //         pay_amount: "10",
    //         quantity: 1,
    //         effective_time: 0,
    //         sequence: 0,
    //         status: 1,
    //         send_asset: "",
    //         send_amount: "",
    //       },
    //     ],
    //     user_used: 0,
    //     in_package_id: 0,
    //     product_category: 0,
    //     xianding: 0,
    //     total_stock: 0,
    //     stock: 0,
    //     stock_show: 0,
    //     xianding_start_time: null,
    //     xianding_end_time: null,
    //     level_need: 0,
    //     level_info: {
    //       id: 0,
    //       created_at: "0001-01-01T00:00:00Z",
    //       updated_at: "0001-01-01T00:00:00Z",
    //       category: "",
    //       name: "",
    //       icon: "",
    //       sequence: 0,
    //       threshold: 0,
    //       key: "",
    //       grade: 0,
    //     },
    //   },
    //   {
    //     id: 332,
    //     created_at: "2023-10-09T22:48:55+08:00",
    //     updated_at: "2023-10-09T22:48:55+08:00",
    //     name: "杯子69",
    //     pic: "",
    //     category: 1,
    //     pay_asset: "shengdou",
    //     asset_info: {
    //       id: 0,
    //       created_at: "0001-01-01T00:00:00Z",
    //       updated_at: "0001-01-01T00:00:00Z",
    //       name: "",
    //       icon: "",
    //       display_name: "",
    //       status: 0,
    //       wallet_name: "",
    //     },
    //     goods_id: 446,
    //     item_type: "6",
    //     goods_info: {
    //       id: 446,
    //       created_at: "2023-10-09T22:11:43+08:00",
    //       updated_at: "2023-12-19T15:09:19+08:00",
    //       name: "杯子66",
    //       desc: "杯子66",
    //       item_class: "1",
    //       item_type: "6",
    //       item_level: "1",
    //       is_in_package: 1,
    //       is_unique: 1,
    //       can_use: 1,
    //       attr_info: "杯子66",
    //       sequence: 0,
    //       effect_param: "",
    //       icon_path:
    //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/47c10085-a09f-4014-b7c8-5fb29572e4e8.png",
    //       show_resource_path:
    //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/1b9f2561-4802-4644-83b4-56f6c8f56884.svga.svga",
    //       show_resource_type: "full",
    //       tid: "BEIZI",
    //       can_sale: 0,
    //       sale_price: "10",
    //       can_combine: 0,
    //       is_stack: 1,
    //       stack_limit: 999999,
    //       giftwall_status: 1,
    //     },
    //     sequence: 0,
    //     status: 0,
    //     skuList: [
    //       {
    //         id: 341,
    //         created_at: "2023-10-09T22:48:55+08:00",
    //         updated_at: "2023-10-09T22:48:55+08:00",
    //         product_id: 332,
    //         pay_amount: "10",
    //         quantity: 1,
    //         effective_time: 0,
    //         sequence: 0,
    //         status: 1,
    //         send_asset: "",
    //         send_amount: "",
    //       },
    //     ],
    //     user_used: 0,
    //     in_package_id: 0,
    //     product_category: 0,
    //     xianding: 0,
    //     total_stock: 0,
    //     stock: 0,
    //     stock_show: 0,
    //     xianding_start_time: null,
    //     xianding_end_time: null,
    //     level_need: 0,
    //     level_info: {
    //       id: 0,
    //       created_at: "0001-01-01T00:00:00Z",
    //       updated_at: "0001-01-01T00:00:00Z",
    //       category: "",
    //       name: "",
    //       icon: "",
    //       sequence: 0,
    //       threshold: 0,
    //       key: "",
    //       grade: 0,
    //     },
    //   },
    //   {
    //     id: 332,
    //     created_at: "2023-10-09T22:48:55+08:00",
    //     updated_at: "2023-10-09T22:48:55+08:00",
    //     name: "杯子68s",
    //     pic: "",
    //     category: 1,
    //     pay_asset: "shengdou",
    //     asset_info: {
    //       id: 0,
    //       created_at: "0001-01-01T00:00:00Z",
    //       updated_at: "0001-01-01T00:00:00Z",
    //       name: "",
    //       icon: "",
    //       display_name: "",
    //       status: 0,
    //       wallet_name: "",
    //     },
    //     goods_id: 446,
    //     item_type: "6",
    //     goods_info: {
    //       id: 446,
    //       created_at: "2023-10-09T22:11:43+08:00",
    //       updated_at: "2023-12-19T15:09:19+08:00",
    //       name: "杯子66",
    //       desc: "杯子66",
    //       item_class: "1",
    //       item_type: "6",
    //       item_level: "1",
    //       is_in_package: 1,
    //       is_unique: 1,
    //       can_use: 1,
    //       attr_info: "杯子66",
    //       sequence: 0,
    //       effect_param: "",
    //       icon_path:
    //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/47c10085-a09f-4014-b7c8-5fb29572e4e8.png",
    //       show_resource_path:
    //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/1b9f2561-4802-4644-83b4-56f6c8f56884.svga.svga",
    //       show_resource_type: "full",
    //       tid: "BEIZI",
    //       can_sale: 0,
    //       sale_price: "10",
    //       can_combine: 0,
    //       is_stack: 1,
    //       stack_limit: 999999,
    //       giftwall_status: 1,
    //     },
    //     sequence: 0,
    //     status: 0,
    //     skuList: [
    //       {
    //         id: 341,
    //         created_at: "2023-10-09T22:48:55+08:00",
    //         updated_at: "2023-10-09T22:48:55+08:00",
    //         product_id: 332,
    //         pay_amount: "10",
    //         quantity: 1,
    //         effective_time: 0,
    //         sequence: 0,
    //         status: 1,
    //         send_asset: "",
    //         send_amount: "",
    //       },
    //     ],
    //     user_used: 0,
    //     in_package_id: 0,
    //     product_category: 0,
    //     xianding: 0,
    //     total_stock: 0,
    //     stock: 0,
    //     stock_show: 0,
    //     xianding_start_time: null,
    //     xianding_end_time: null,
    //     level_need: 0,
    //     level_info: {
    //       id: 0,
    //       created_at: "0001-01-01T00:00:00Z",
    //       updated_at: "0001-01-01T00:00:00Z",
    //       category: "",
    //       name: "",
    //       icon: "",
    //       sequence: 0,
    //       threshold: 0,
    //       key: "",
    //       grade: 0,
    //     },
    //   },
    //   {
    //     id: 332,
    //     created_at: "2023-10-09T22:48:55+08:00",
    //     updated_at: "2023-10-09T22:48:55+08:00",
    //     name: "杯子60s",
    //     pic: "",
    //     category: 1,
    //     pay_asset: "shengdou",
    //     asset_info: {
    //       id: 0,
    //       created_at: "0001-01-01T00:00:00Z",
    //       updated_at: "0001-01-01T00:00:00Z",
    //       name: "",
    //       icon: "",
    //       display_name: "",
    //       status: 0,
    //       wallet_name: "",
    //     },
    //     goods_id: 446,
    //     item_type: "6",
    //     goods_info: {
    //       id: 446,
    //       created_at: "2023-10-09T22:11:43+08:00",
    //       updated_at: "2023-12-19T15:09:19+08:00",
    //       name: "杯子66",
    //       desc: "杯子66",
    //       item_class: "1",
    //       item_type: "6",
    //       item_level: "1",
    //       is_in_package: 1,
    //       is_unique: 1,
    //       can_use: 1,
    //       attr_info: "杯子66",
    //       sequence: 0,
    //       effect_param: "",
    //       icon_path:
    //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/47c10085-a09f-4014-b7c8-5fb29572e4e8.png",
    //       show_resource_path:
    //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/1b9f2561-4802-4644-83b4-56f6c8f56884.svga.svga",
    //       show_resource_type: "full",
    //       tid: "BEIZI",
    //       can_sale: 0,
    //       sale_price: "10",
    //       can_combine: 0,
    //       is_stack: 1,
    //       stack_limit: 999999,
    //       giftwall_status: 1,
    //     },
    //     sequence: 0,
    //     status: 0,
    //     skuList: [
    //       {
    //         id: 341,
    //         created_at: "2023-10-09T22:48:55+08:00",
    //         updated_at: "2023-10-09T22:48:55+08:00",
    //         product_id: 332,
    //         pay_amount: "10",
    //         quantity: 1,
    //         effective_time: 0,
    //         sequence: 0,
    //         status: 1,
    //         send_asset: "",
    //         send_amount: "",
    //       },
    //     ],
    //     user_used: 0,
    //     in_package_id: 0,
    //     product_category: 0,
    //     xianding: 0,
    //     total_stock: 0,
    //     stock: 0,
    //     stock_show: 0,
    //     xianding_start_time: null,
    //     xianding_end_time: null,
    //     level_need: 0,
    //     level_info: {
    //       id: 0,
    //       created_at: "0001-01-01T00:00:00Z",
    //       updated_at: "0001-01-01T00:00:00Z",
    //       category: "",
    //       name: "",
    //       icon: "",
    //       sequence: 0,
    //       threshold: 0,
    //       key: "",
    //       grade: 0,
    //     },
    //   },
    // ];
    // setTargetData({
    //   count: num,
    //   data: datt,
    // });
    // setTimeout(() => {
    //   goodsModelRef.current.show();
    // }, 2800);
    try {
      // 原来的礼物接口/api/v1/turntable/lottery
      const res = await Http.postForm("/api/v1/lucky_bot_game/start", {
        turntable_id: id,
        count: num,
        convert: sendConvert,
      });
      if (res?.status === 10000) {
        myLucky.current.cutStart();
        error.current = false;
        //     myLucky.current.play(num, res?.data);
        setTargetData({
          count: num,
          data: res?.data,
        });
        setDisable(true);
        setTimeout(() => {
          setLoading(false);
          myLucky.current.noStart();
          goodsModelRef.current.show();
        }, 2000);

        getWalletList();
      } else {
        error.current = true;
        // setTimeout(() => {
        //   myLucky.current.stop();
        // }, 5000);

        message.error(res?.message);
      }
    } catch (error) {
      message.error("网络错误，请检查网络设置");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };
  //进度
  const [open, setOpen] = React.useState(false);
  const [dataChange, setDataChange] = React.useState(0);
  const [process, setProcess] = React.useState(0);
  const getJingdu = async () => {
    setJinduask(false);
    try {
      const res = await Http.get("/api/v1/lucky_bot_game/getProcessById", {});
      if (res?.status === 10000) {
        // let data = parseFloat(res.data).toFixed(2) || 0;
        let data = truncateDecimals(res.data, 2);
        setDataChange(data * 100);
        setProcess(res.data);
      } else {
      }
    } catch (error) {
      // message.error("网络错误，请检查网络设置");
    } finally {
      setJinduask(true);
    }
  };
  function truncateDecimals(numStr, digits) {
    // var numStr = num.toString();
    var decimalIndex = numStr.indexOf(".");

    if (decimalIndex !== -1) {
      // 截取小数点后 digits 位
      numStr = numStr.slice(0, decimalIndex + digits + 1);
    }

    return parseFloat(numStr);
  }

  React.useEffect(() => {
    if (token && token !== storeToken) {
      dispatch(setToken(token));
    }
  }, [dispatch, storeToken, token]);

  const init = async () => {
    try {
      await Promise.all([getData(), getTurntableRes(), getWalletList()]);
    } catch (error) {
    } finally {
      setInitLoading(false);
    }
  };
  const [priceNew, setPriceNew] = React.useState(0);
  const getNew = async () => {
    try {
      const res = await Http.get("/api/v1/lucky_bot_game/getById", { id: 1 });
      if (res?.status === 10000) {
        setPriceNew(res?.data.price);
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      // message.error("网络错误，请检查网络设置");
    } finally {
    }
  };
  const [jinduask, setJinduask] = React.useState(true);
  const [turntask, setTurntask] = React.useState(true);
  React.useEffect(() => {
    init();
    getNew();
    const intervalId = setInterval(() => {
      if (jinduask) {
        getJingdu();
      }
      if (turntask) {
        getTurntableRes();
      }
    }, 1000);
    return () => {
      clearInterval(intervalId); // 在组件卸载时清除定时器
    };
  }, []);

  React.useEffect(() => {
    setTargetData({
      count: 0,
      data: [],
    });
    // const interval = setInterval(() => {
    //   setDataChange((prevCount) => {
    //     const newCount = prevCount + 1;
    //     if (newCount === 100) {
    //       clearInterval(interval);
    //     }
    //     return newCount;
    //   });
    // }, 200);

    // // 返回清理函数以清除定时器
    // return () => clearInterval(interval);
  }, [index]);

  const renderTabItem = (item, _index) => {
    const isSelected = index === _index;

    const className = () => {
      if (isSelected && _index === 0) {
        return "tab-item-left-active";
      } else if (!isSelected && _index === 0) {
        return "tab-item-left";
      } else if (isSelected && _index === 1) {
        return "tab-item-middle-active";
      } else if (!isSelected && _index === 1) {
        return "tab-item-middle";
      } else if (isSelected && _index === 2) {
        return "tab-item-right-active";
      } else if (!isSelected && _index === 2) {
        return "tab-item-right";
      }
    };

    return (
      <div
        className={className()}
        key={_index}
        onClick={() => {
          if (disable) {
            return;
          }

          setIndex(_index);
        }}
      >
        {item}
      </div>
    );
  };

  if (initLoading) {
    return (
      <div className="load-container">
        <div className="loading">
          <Spin indicator={antIcon} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        index === 0
          ? "wheel-container wheel-container-base"
          : index === 1
          ? "wheel-container wheel-container-middle"
          : "wheel-container wheel-container-advanced"
      }
    >
      <GoodsModel
        ref={goodsModelRef}
        targetData={targetData}
        setTargetData={setTargetData}
        lottery={lottery}
        index={index}
        data={data}
        productList={data[index]?.product_list ?? []}
      />
      {/* 规则弹窗 */}
      <Windows ref={WindowRef} />
      <Zjwindow ref={zhongWindowRef} />

      <InputNumberModel
        ref={inputNumberModelRef}
        lottery={lottery}
        index={index}
        data={data}
        onChange={(value) => {
          setCustomCount(value);
        }}
      />
      <div className="more-container">
        <div className="more-welcome">
          {/* {index === 1 ? (
            <img src={welcomes} alt="" />
          ) : ( */}
          {/* <img src={logo} alt="" /> */}
          {/* )} */}
        </div>

        <div
          className="wheel-more"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <Popover
            placement="topLeft"
            title={null}
            open={open}
            content={
              <div>
                <div
                  className="more-item"
                  onClick={(event) => {
                    // navigate("/dazhuanpan/activity/description");
                    event.stopPropagation();
                    WindowRef.current.show();
                    setOpen(false);
                  }}
                >
                  规则说明
                </div>
                {/* <div
                  className="more-item"
                  onClick={() => {
                    navigate("/dazhuanpan/ranking/list");
                  }}
                >
                  排行榜单
                </div> */}
                <div
                  className="more-item"
                  onClick={() => {
                    zhongWindowRef.current.show2();
                    setOpen(false);
                    // navigate("/dazhuanpan/winning/record");
                  }}
                >
                  中奖记录
                </div>
              </div>
            }
            trigger="click"
          >
            <img src={btn_zp_menu} alt="" />
          </Popover>
        </div>
      </div>
      {/* 初中高级 */}
      {/* <div className="tab-container">{tabs.map(renderTabItem)}</div> */}
      <div className="star-add-container">
        <StarAdd
          count={count}
          shengDou={shengDou}
          onChange={(data) => {
            setConvert(data);
          }}
          getWalletList={getWalletList}
        />
      </div>
      <div className="text-container">
        <Text record={record} />
        <div className="center_img1">
          {process >= 1 ? (
            <img src={luckyImg} alt="" />
          ) : (
            <img src={commonImg} alt="" />
          )}
          {/* <img src={commonImg} alt="" /> */}
          {/* <img src={luckyImg} alt="" /> */}
        </div>
      </div>
      <div className="lucky-wheel-container ">
        <div className={isRotating ? "rotate" : " "}>
          {index === 0 ? (
            <BaseWheel
              id={data[0]?.id}
              productList={data[0]?.product_list ?? []}
              ref={myLucky}
              findIndex={findIndex}
              targetData={targetData}
              setTargetData={setTargetData}
              showGiftModel={showGiftModel}
              lottery={lottery}
              selectState={selectState}
            />
          ) : index === 1 ? (
            <MiddleWheel
              id={data[1]?.id}
              productList={data[1]?.product_list ?? []}
              ref={myLucky}
              findIndex={findIndex}
              targetData={targetData}
              setTargetData={setTargetData}
              showGiftModel={showGiftModel}
              lottery={lottery}
              selectState={selectState}
            />
          ) : (
            <AdvanceWheel
              id={data[2]?.id}
              productList={data[2]?.product_list ?? []}
              ref={myLucky}
              findIndex={findIndex}
              targetData={targetData}
              setTargetData={setTargetData}
              showGiftModel={showGiftModel}
              lottery={lottery}
              selectState={selectState}
            />
          )}
        </div>
      </div>
      {/* <div className="house_box">
        {index === 0 ? (
          <img src={bg_zp_chuji_5}></img>
        ) : index === 1 ? (
          <img src={bg_zp_chuji_6}></img>
        ) : (
          <img src={bg_zp_chuji_7}></img>
        )}
      </div> */}
      {/* 点击go 开始抽奖 */}
      {/* <div className="house_center">
        {index === 0 ? (
          <img
            onClick={() => {
              myLucky.current.onStart();
            }}
            src={go1}
          ></img>
        ) : index === 1 ? (
          <img
            onClick={() => {
              myLucky.current.onStart();
            }}
            src={go2}
          ></img>
        ) : (
          <img
            onClick={() => {
              myLucky.current.onStart();
            }}
            src={go3}
          ></img>
        )}
      </div> */}
      {index === 0 ? (
        <div className="buttons">
          <div
            className={selectState.mode === 1 ? "button" : "disable-button"}
            onClick={() => {
              setSelectState({
                mode: 1,
                count: 1,
              });
              lottery(1, 1);
            }}
          >
            <div className="button-container">
              <div className="count">抽1次</div>
              <div className="label">{priceNew}积分/次</div>
            </div>
          </div>
          <div
            className={selectState.mode === 2 ? "button" : "disable-button"}
            onClick={() => {
              setSelectState({
                mode: 2,
                count: 10,
              });
              lottery(1, 10);
            }}
          >
            <div className="button-container">
              <div className="count">抽10次</div>
              <div className="label">{priceNew * 10}积分/次</div>
            </div>
          </div>
          <div
            className={selectState.mode === 3 ? "button" : "disable-button"}
            onClick={() => {
              setSelectState({
                mode: 3,
                count: customCount,
              });
              lottery(1, customCount);
            }}
          >
            <div className="button-container">
              <div className="count">抽{customCount}次</div>
              <div className="label">{priceNew * customCount}积分/次</div>
            </div>
          </div>
        </div>
      ) : index === 1 ? (
        <div className="buttons1">
          <div
            className={selectState.mode === 1 ? "button" : "disable-button"}
            onClick={() => {
              setSelectState({
                mode: 1,
                count: 1,
              });
            }}
          >
            <div className="button-container">
              <div className="count">抽1次</div>
              <div className="label">{price}积分/次</div>
            </div>
          </div>
          <div
            className={selectState.mode === 2 ? "button" : "disable-button"}
            onClick={() => {
              setSelectState({
                mode: 2,
                count: 10,
              });
            }}
          >
            <div className="button-container">
              <div className="count">抽10次</div>
              <div className="label">{price * 10}积分/次</div>
            </div>
          </div>
          <div
            className={selectState.mode === 3 ? "button" : "disable-button"}
            onClick={() => {
              setSelectState({
                mode: 3,
                count: customCount,
              });
            }}
          >
            <div className="button-container">
              <div className="count">抽{customCount}次</div>
              <div className="label">{price * customCount}积分/次</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="buttons">
          <div
            className={
              selectState.mode === 1
                ? "button-advanced"
                : "disable-button-advanced"
            }
            onClick={() => {
              setSelectState({
                mode: 1,
                count: 1,
              });
            }}
          >
            <div className="button-container">
              <div className="count">抽1次</div>
              <div className="label">{price}积分/次</div>
            </div>
          </div>
          <div
            className={
              selectState.mode === 2
                ? "button-advanced"
                : "disable-button-advanced"
            }
            onClick={() => {
              setSelectState({
                mode: 2,
                count: 10,
              });
            }}
          >
            <div className="button-container">
              <div className="count">抽10次</div>
              <div className="label">{price * 10}积分/次</div>
            </div>
          </div>
          <div
            className={
              selectState.mode === 3
                ? "button-advanced"
                : "disable-button-advanced"
            }
            onClick={() => {
              setSelectState({
                mode: 3,
                count: customCount,
              });
            }}
          >
            <div className="button-container">
              <div className="count">抽{customCount}次</div>
              <div className="label">{price * customCount}积分/次</div>
            </div>
          </div>
        </div>
      )}
      <div
        className={"custom"}
        onClick={() => {
          inputNumberModelRef.current.show();
        }}
      >
        {/* <div className="progress_box">
          <div className="mix_out">
            <img className="mix_box" src={mixJingdu}></img>
            <img className="max_box" src={MaxImg}></img>
            <img className="icon_box" src={iconImg}></img>
          </div>
        </div> */}
        <div className="progress_box">
          <div className="mix_out">
            <img className="mix_box" src={mixJingdu}></img>
            <img
              className={dataChange > 90 ? "max_box2" : "max_box"}
              style={{ width: `${dataChange}%` }}
              src={MaxImg}
            ></img>
            <view className="xin_box">
              <img
                className="icon_box"
                style={{ marginLeft: `calc(${dataChange}% - 12px )` }}
                src={iconImg}
              ></img>
            </view>
          </div>
        </div>
        <div>自定义次数</div>
      </div>
    </div>
  );
};

export default Wheel;
